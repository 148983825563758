

export const cleanupLocalStorage = () => {
  let key = "sidebar";
  const value = localStorage.getItem("sidebar");
  // Tüm localStorage'ı temizle
  localStorage.clear();

  localStorage.setItem(key, value);
};
