/** @format */
import axios from "axios";
import CheckToken from "../../../utils/checkToken/CheckToken";
import { cookies } from "../../../utils/cookie";
import { refreshToken } from "../../../api/auth/refreshToken";

export const notificationSync = async (
  startDate,
  endDate,
  apiKey,
  queueAdress
) => {
  const removeCookies = () => {
    cookies.remove("jwt-access", { path: "/" });
    cookies.remove("jwt-access-expires", { path: "/" });
    cookies.remove("jwt-refresh", { path: "/" });
    cookies.remove("jwt-refresh-expires", { path: "/" });
  };

  const currentRefresh = cookies.get("jwt-refresh");
  if (cookies.get("jwt-access")) {
    if (currentRefresh) {
      try {
        let resp = await refreshToken(currentRefresh);
        cookies.set("jwt-access", resp?.data?.access?.token);
        cookies.set("jwt-access-expires", resp?.data?.access?.expires);
        cookies.set("jwt-refresh", resp?.data?.refresh?.token);
        cookies.set("jwt-refresh-expires", resp?.data?.refresh?.expires);
      } catch (error) {
        throw new Error("Error request server");
      }
    } else {
      removeCookies();
    }
  } else {
    removeCookies();
  }

  let token = await CheckToken();

  return await axios.post(
    `${process.env.REACT_APP_PROXY_API_URL}/rahat-musavir/sync-notification`,
    {
      startDate,
      endDate,
      apiKey,
      dbReqUrl: process.env.REACT_APP_API_URL,
      queueAdress,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
