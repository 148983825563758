/**
 * Vergi Kimlik Numarası doğrulama fonksiyonu
 * @param {string} vkn - Doğrulanacak VKN
 * @returns {boolean} - VKN'nin geçerli olup olmadığı
 */
const checkVknValidation = (vkn) => {
  if (
    vkn === "1234567890" ||
    vkn === "0000000000" ||
    vkn === "0123456789" ||
    vkn.split("").every((char) => char === vkn[0]) // Tüm rakamlar aynı mı kontrol eder
  ) {
    return false;
  }

  if (vkn.length === 10) {
    const v = [];
    const lastDigit = Number(vkn.charAt(9));
    for (let i = 0; i < 9; i++) {
      const tmp = (Number(vkn.charAt(i)) + (9 - i)) % 10;
      v[i] = (tmp * 2 ** (9 - i)) % 9;
      if (tmp !== 0 && v[i] === 0) v[i] = 9;
    }
    const sum = v.reduce((a, b) => a + b, 0) % 10;
    return (10 - (sum % 10)) % 10 === lastDigit;
  }
  return false;
};

export default checkVknValidation;
