import React, { useState } from "react";
import { register } from "../../api/user/register";
import Swal from "sweetalert2";
import "../../swall.css";
import {
  Grid,
  Button,
  Typography,
  Checkbox,
  TextField,
  InputAdornment,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import checkVknValidation from "../../utils/validateVKN/validateVKN";

const Register = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [tckn, setTckn] = useState("");
  const [vkn, setVkn] = useState("");
  const [ivd_password, setIvd_password] = useState("");
  const [number, setNumber] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => event.preventDefault();
  const navigate = useNavigate();
  const [agree, setAgree] = useState(false);
  const [errors, setErrors] = useState({});
  const [alertShow, setAlertShow] = useState(false);
  const [open, setOpen] = React.useState(false);

  const handleBlur = (field) => (e) => {
    const value = e.target.value;
    let tempErrors = { ...errors };

    switch (field) {
      case "title":
        tempErrors.title = !value ? "Ünvan gereklidir." : "";
        break;
      case "vkn":
        tempErrors.vkn = !value
          ? "Vergi Kimlik Numarası gereklidir."
          : /^\d{10}$/.test(value)
          ? ""
          : "VKN 10 rakamdan oluşmalıdır.";
        break;
      case "tckn":
        tempErrors.tckn = !value
          ? "TC Numarası gereklidir."
          : /^\d{11}$/.test(value)
          ? ""
          : "TC No 11 rakamdan oluşmalıdır.";
        break;
      case "ivd_password":
        tempErrors.ivd_password = !value
          ? "Dijital Vergi Dairesi Şifresi gereklidir."
          : "";
        break;
      case "email":
        tempErrors.email = !value
          ? "E-Posta adresi gereklidir."
          : /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)
          ? ""
          : "Geçerli bir e-posta adresi giriniz.";
        break;
      case "number":
        tempErrors.number = !value
          ? "Telefon numarası gereklidir."
          : /[^5\d\s]/.test(value) || /\s/.test(value)
          ? "Telefon numarası sadece rakam ve '5' ile başlamalıdır, boşluk içermemelidir."
          : /^5\d{9}$/.test(value)
          ? ""
          : "Telefon numarası '5423158812' formatında olmalıdır.";
        break;
      case "password":
        tempErrors.password = !value
          ? "Şifre gereklidir."
          : !/[a-zA-Z]/.test(value)
          ? "Şifre en az bir harf içermelidir."
          : !/\d/.test(value)
          ? "Şifre en az bir rakam içermelidir."
          : value.length < 8
          ? "Şifre en az 8 karakter uzunluğunda olmalıdır."
          : "";
        break;
      default:
        break;
    }

    setErrors(tempErrors);

    // Eğer bir hata varsa alert göster
    setAlertShow(Object.values(tempErrors).some((err) => err));
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    const newDataToSend = {
      title,
      ivd_credentials: {
        tckn,
      },
      vkn,
      email,
      number: [number],
      password,
      ivd_password,
    };
    let vknControl = checkVknValidation(vkn);
    if (!vknControl) {
      Swal.fire({
        text: "Lütfen geçerli bir vergi kimlik numarası giriniz!",
        icon: "error",
        confirmButtonText: "Tamam",
        customClass: { htmlContainer: ".swal2-container" },
      });
    }
    if (
      vkn === "" ||
      title === "" ||
      email === "" ||
      ivd_password === "" ||
      ivd_password.trim() === "" ||
      number?.[0] === "" ||
      number?.length === 0 ||
      !/[a-zA-Z]/.test(password) ||
      !/\d/.test(password) ||
      password.length < 8 ||
      password === ""
    ) {
      setAlertShow(true);
      return;
    }

    Swal.fire({
      text: "Girdiğiniz bilgiler ile kayıt olacaksınız, lütfen bilgilerin doğru olduğundan emin olun. Devam etmek istiyor musunuz?",
      icon: "question",
      showDenyButton: true,
      denyButtonText: "Hayır, devam etme",
      confirmButtonText: "Evet, devam et",
      customClass: { htmlContainer: ".swal2-container" },
    }).then((result) => {
      if (result.isConfirmed) {
        setOpen(true);
        register(newDataToSend)
          .then((resp) => {
            setOpen(false);
            Swal.fire({
              text: `${resp.data?.message}\n${email} E-posta'm ile Müşavir Yönetim SMMM Yönetim uygulamasına kayıt oldum.`,
              icon: "success",
              confirmButtonText: "Gönder",
              confirmButtonColor: "green",
            }).then((result) => {
              if (result.isConfirmed) {
                window.open(
                  `https://api.whatsapp.com/send/?phone=5423158812&text=${email}%20E-posta%27m%20ile%20M%C3%BC%C5%9Favir+Y%C3%B6netim+SMMM+Y%C3%B6netim+uygulamas%C4%B1na+kay%C4%B1t+oldum.&type=phone_number&app_absent=0`
                );
              }
            });
          })
          .catch((err) => {
            setOpen(false);

            Swal.fire({
              text: err.response.data?.message,
              icon: "error",
              confirmButtonText: "Tamam",
            });
          });
      }
    });
  };

  return (
    <Grid
      container
      sx={{
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "space-between",
        padding: isMobile ? "2vh" : "5vh",
      }}
      spacing={2}
    >
      {!isMobile && (
        <Grid
          item
          md={8.5}
          sm={8.5}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Grid
            component="img"
            sx={{
              width: "40%",
              height: "40%",
              transition: "transform 0.3s ease-in-out",
              "&:hover": {
                transform: "scale(1.05)",
              },
            }}
            alt="My Image"
            src={`images/forgot.png`}
          />
        </Grid>
      )}

      <Grid
        item
        xs={12}
        md={3.5}
        sm={3.5}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          alignItems: "center",
          justifyContent: "center",
          height: isMobile ? "auto" : "80vh",
          marginTop: isMobile ? "5vh" : "10vh",
          marginBottom: isMobile ? "5vh" : "10vh",
          backgroundColor: "#ffffff",
          padding: "2vh",
          width: isMobile ? "100%" : "auto",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
          }}
        >
          <Typography
            variant={isMobile ? "h4" : "h2"}
            sx={{
              textAlign: "center",
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
            }}
          >
            <img
              src="images/rahatbeyan.png"
              style={{
                objectFit: "cover",
                width: isMobile ? "100%" : "70%",
                marginBottom: { xs: "0.5em", md: "0" },
                marginRight: { xs: "0", md: "1em" },
              }}
              alt="Logo"
            />
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: "red",
              marginLeft: "auto",
              fontWeight: "700",
              marginRight: isMobile ? "1em" : "4em",
              justifyContent: "flex-end",
              display: "flex",
            }}
          >
            <img
              src="https://servicesuser.rahatsistem.com.tr/images/products/rahatsistem/rs-logo/by-rs/1x/by-rahatsistem-logo.png"
              style={{
                objectFit: "cover",
                width: isMobile ? "30%" : "20%",
                marginBottom: { xs: "0.5em", md: "0" },
                marginRight: { xs: "0", md: "2%" },
              }}
              alt="Logo"
            />
          </Typography>
          <Typography variant={isMobile ? "h6" : "h5"}>
            Rahat Müşavir - Kayıt Formu
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          {alertShow && <Grid item xs={12}></Grid>}

          <Grid item xs={12} md={6}>
            <TextField
              className="input-field"
              fullWidth
              placeholder="Ünvan Giriniz."
              label="Ünvan"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              onBlur={handleBlur("title")}
              error={!!errors.title}
              helperText={errors.title}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              className="input-field"
              fullWidth
              placeholder="Vergi Kimlik Numarası (Zorunlu)"
              label="Vergi Kimlik Numarası"
              InputLabelProps={{ shrink: true }}
              inputProps={{ maxLength: 10 }}
              variant="outlined"
              value={vkn}
              onChange={(e) => setVkn(e.target.value)}
              onBlur={handleBlur("vkn")}
              error={!!errors.vkn}
              helperText={errors.vkn}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              className="input-field"
              sx={{
                marginTop: "2%",
              }}
              fullWidth
              placeholder="TC Numaranızı Giriniz."
              label="TC Numarası"
              InputLabelProps={{ shrink: true }}
              inputProps={{ maxLength: 11 }}
              variant="outlined"
              value={tckn}
              onChange={(e) => setTckn(e.target.value)}
              onBlur={handleBlur("tckn")}
              error={!!errors.tckn}
              helperText={errors.tckn}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              className="input-field"
              fullWidth
              placeholder="Dijital Vergi Dairesi Şifresi Giriniz."
              label="Dijital Vergi Dairesi Şifresi"
              InputLabelProps={{ shrink: true }}
              inputProps={{ maxLength: 6 }}
              variant="outlined"
              value={ivd_password}
              onChange={(e) => setIvd_password(e.target.value)}
              onBlur={handleBlur("ivd_password")}
              error={!!errors.ivd_password}
              helperText={errors.ivd_password}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              className="input-field"
              fullWidth
              placeholder="E-Posta Adresi Giriniz."
              label="E-Posta"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onBlur={handleBlur("email")}
              error={!!errors.email}
              helperText={errors.email}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              className="input-field"
              fullWidth
              placeholder="Telefon Numarası Giriniz."
              label="Telefon Numarası"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              onBlur={handleBlur("number")}
              error={!!errors.number}
              helperText={errors.number}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              className="input-field"
              label="Şifre"
              placeholder="Şifre Giriniz."
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onBlur={handleBlur("password")}
              error={!!errors.password}
              helperText={errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="small"
                    >
                      {showPassword ? (
                        <Visibility
                          fontSize="inherit"
                          style={{ fontSize: "1rem" }}
                        />
                      ) : (
                        <VisibilityOff
                          fontSize="inherit"
                          style={{ fontSize: "1rem" }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", alignItems: "center", width: "100%" }}
        >
          <Checkbox
            checked={agree}
            className="default-checked"
            onChange={(e) => setAgree(e.target.checked)}
          />
          <Button
            className="unframed-button"
            sx={{ color: "#786af2", textDecoration: "none" }}
          >
            Gizlilik Politikası ve Şartlarını
          </Button>
          <Typography fontSize={14}>Kabul Ediyorum</Typography>
        </Grid>

        <Grid item xs={12} sx={{ width: "100%" }}>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            type="submit"
            className="custom-button"
            onClick={handleRegister}
          >
            Kayıt Ol
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Typography variant="body2">Zaten bir hesabınız var mı? </Typography>

          <Button
            variant="body2"
            onClick={() => {
              navigate("/login");
            }}
            className="unframed-button "
            sx={{ color: "#786af2", textDecoration: "none" }}
          >
            Giriş yap
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Register;
