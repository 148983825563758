/** @format */

// Düzeltildi

import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, Grid, Typography } from "@mui/material";
import { AgGridReact } from "ag-grid-react";

import Swal from "sweetalert2";
import date from "date-and-time";
import { getDeclarationMailhistory } from "../../api/declaration/getDeclarationMailHistory";
import { lighten } from "polished";
const MailHistoryModal = ({ data, setModalOpen, openModal }) => {
  const [history, setHistory] = useState([]);
  useEffect(() => {
    if (openModal) {
      getDeclarationMailhistory(data)
        .then((resp) => {
          setHistory(resp.data.data);
          // console.log(resp.data.data);
        })
        .catch((err) =>
          Swal.fire({
            icon: "warning",
            title: err.response.data.message,
            confirmButtonText: "Tamam",
          })
        );
      // console.log(history, "2");
    }
  }, [data]);
  const columnDefs = [
    {
      field: "message",
      flex: 2.5,
      headerName: "İşlem",
      cellStyle: (params) => {
        return {
          textAlign: "start",
          fontWeight: "700",
          color:
            params.data.status === 1
              ? "blue"
              : params.data.status === 2
              ? "gray"
              : params.data.whatsappId && params.data.status === 4
              ? "red"
              : params.data.status === 4 ||
                params.data.message.includes("okundu")
              ? "green"
              : params.data.status === 3
              ? "orange"
              : "red",
        };
      },
    },
    {
      field: data[1] === "email" ? "mailAdress" : "phoneNumber",
      flex: 1,
      headerName: data[1] === "email" ? "E-posta adresi" : "Telefon numarası",
      cellStyle: { textAlign: "start" },
    },
    {
      field: "created_at",
      flex: 1,
      headerName: "İşlem tarihi",
      cellStyle: { textAlign: "start" },
      valueGetter: (props) =>
        date.format(
          new Date(props.data.created_at),
          "YYYY-MM-DD HH:mm:ss ",
          false
        ),
    },
  ];
  const getRowStyle = (params) => {
    if (params.data.status === 6 || params.data.status === 5) {
      return { background: lighten(0.4, "red") };
    }
    return null;
  };

  return (
    <Dialog open={openModal} onClose={() => setModalOpen(false)} maxWidth="xl">
      <DialogContent>
        <Grid container sx={{ minHeight: "30vh", width: "50vw" }} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" textAlign={"center"}>
              {" "}
              {data[1] === "whatsapp"
                ? "Tahakkuk Whatsapp Geçmişi"
                : "Tahakkuk E-posta Geçmişi"}
            </Typography>{" "}
          </Grid>

          <Grid item xs={12}>
            <div
              className="ag-theme-quartz"
              style={{
                flex: 1,
                minHeight: "10vh",
                width: "100%",
              }}
            >
              <AgGridReact
                style={{ flex: 1, width: "100%" }}
                rowData={history}
                columnDefs={columnDefs}
                gridOptions={{
                  suppressCellFocus: true,
                }}
                enableCellTextSelection={true}
                domLayout="autoHeight"
                getRowStyle={getRowStyle}
                overlayNoRowsTemplate={
                  data[1] !== "whatsapp"
                    ? "Gösterilecek E-posta geçmişi yok"
                    : "Gösterilecek whatsapp geçmişi yok"
                }
              />
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default MailHistoryModal;
