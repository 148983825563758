import { lighten } from "polished";

export const createColumnDefs = (handleClickButtonDownload) => [
  {
    headerName: "",
    field: "",
    maxWidth: 80,
    minWidth: 80,
    checkboxSelection: true,
    cellRenderer: "agGroupCellRenderer",
    filter: false,
    suppressMenu: true,
  },
  {
    headerName: "Durum",
    field: "status",
    flex:0,
    width: 150,
    headerCheckboxSelection: true,
    cellStyle:{ justifyContent:"center"},
    cellRenderer: (params) => {
      // Gelen veriye göre renk ve metin belirle

      let buttonText = "";
      if (params.data.status === "Completed") {
        buttonText = "Tamamlandı";
      } else if (params.data.status === "Failed") {
        buttonText = "Başarısız";
      } else if (params.data.status === "Cancel") {
        buttonText = "İptal Edildi";
      }else if (params.data.status === "BeingCanceled") {
        buttonText = "İptal Ediliyor";
      } else {
        buttonText = "Devam Ediyor";
      }

      // Buton oluştur
      return (
        <button
          sx={{
            textTransform: "none",
            mb: "3%",
          }}
          style={{
            backgroundColor:
              buttonText === "Tamamlandı"
                ? lighten(0.4, "#5cb85c")
                : buttonText === "Başarısız"
                ? lighten(0.2, "#d9534f")
                : lighten(0.3, "#f0ad4e"),
            color:
              buttonText === "Tamamlandı"
                ? "#12450f"
                : buttonText === "Başarısız"
                ? lighten(0.4, "#d9534f")
                : "#b3545c",
            padding: "6px 12px",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
            transition: "background-color 0.3s ease",
            width: "120px", // Tüm butonların genişliği (örneğin 200 piksel)
            "&:hover": {
              backgroundColor: "#1565c0", // Hover rengi
            },
          }}
        >
          {buttonText}
        </button>
      );
    },

    // filterParams: {
    //   valueFormatter: function (params) {
    //     console.log(params);
    //     if (params.value === "Completed") {
    //       return "Tamamlandı";
    //     } else if (params.value === "Failed") {
    //       return "Başarısız";
    //     } else if (params.value === "Waiting") {
    //       return "Bekleniyor";
    //     } else if (params.value === "Canceled") {
    //       return "İptal Ediliyor";
    //     } else if (params.value === "Cancel") {
    //       return "İptal Edildi";
    //     } else {
    //       return "Hata Oluştu";
    //     }
    //   },
    // },
  },

  {
    field: "createdAt",
    flex: 1,
    headerName: "Tarih",
    maxWidth: 145,
    filter: false,
    cellStyle: { textAlign: "start" },
    // checkboxSelection: true,
    // headerCheckboxSelection: true,
  },
  {
    field: "updatedAt",
    flex: 1,
    headerName: "Bitiş Tarihi",
    maxWidth: 145,
    filter: false,
    cellStyle: { textAlign: "start" },
    // checkboxSelection: true,
    // headerCheckboxSelection: true,
  },

  {
    field: "description",
    flex: 1,
    filter: false,
    headerName: "Bilgilendirme",
    cellStyle: { textAlign: "start" },
    
    // headerCheckboxSelection: true,
  },
];
